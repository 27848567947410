<div *ngIf="parceriaInfo != undefined">
  <div [hidden]="!(loading$ | async) ">
    <div class="loading-overlay "></div>
    <div class="loading-info ">
      <i class="fa fa-spinner fa-4x fa-spin "></i>
      <div id="carregando">Aguarde</div>
    </div>
  </div>

  <div *ngIf="!isPortal">
    <app-info-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [modulo]="modulo"></app-info-parceria>
  </div>
  <div class="container">
    <tabset [pills]="false">
      <tab class="nav-item">
        <!--<ng-template tabHeading>
                    <span [hidden]="!(isConsulta || isFiscal || isPortal || isSMTC)">Financeira</span>
                </ng-template>
                -->
        <BR>
        <div class="container" id="filtroCompetencia">
          <div class="row linha-select-resultados">
            <span>&ensp;&ensp;</span>
            <div class="texto-linha-select-resultados">Selecione o período: &ensp;&ensp;</div>
              <selector-datetime-range class="data-range" [isOnlyDataInicio]="false" [showLabelData]="false" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
          </div>
          <BR>
          <BR>
        </div>

        <div class="container">
          <ngb-accordion #acc="ngbAccordion" (panelChange)="acordiaoChange($event)">
            <ngb-panel id="desembolsos" title="{{isConsulta || isFiscal || isPortal || isSMTC || isAuditorExterno ? 'Demonstrativo de Desembolsos' : '1 - Incluir Desembolsos'}}">
              <ng-template ngbPanelContent>
                <app-desembolso-parceria [pesquisaData]="pesquisaData" [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-desembolso-parceria>
              </ng-template>
            </ngb-panel>


            <ngb-panel id="documentos" 
                title="{{isConsulta || isFiscal || isPortal || isSMTC || isAuditorExterno  ? 'Documentos Complementares' : '2 - Incluir Documentos Complementares'}}"
                >
              <ng-template ngbPanelContent>
                <app-prestacao-parcial-parceria [pesquisaData]="pesquisaData" [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"> </app-prestacao-parcial-parceria>
              </ng-template>
            </ngb-panel>


            <ngb-panel id="devolucao" 
                title="{{isConsulta || isFiscal || isPortal || isSMTC || isAuditorExterno  ? 'Devoluções de Recursos e Rendimentos de aplicação/poupança' : '3 - Incluir Devoluções de Recursos e Rendimentos de aplicação/poupança'}}"
                >
              <ng-template ngbPanelContent>
                <app-devolucao-parceria [pesquisaData]="pesquisaData" [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-devolucao-parceria>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="prestacaoContas" 
                title="{{isConsulta || isFiscal || isPortal || isSMTC || isAuditorExterno  ? 'Situação das Prestações de Contas Parciais' : '4 - Enviar Prestação de Contas Parcial'}}"
                >
              <ng-template ngbPanelContent>
                <app-prestacao-contas [pesquisaData]="pesquisaData" [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-prestacao-contas>
              </ng-template>
            </ngb-panel>

            <ngb-panel id="resumo" title="Resumo do Fluxo Financeiro da Entidade">
              <ng-template ngbPanelContent>
                <app-fluxo-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"></app-fluxo-parceria>
              </ng-template>
            </ngb-panel>

           <div *ngIf="isFiscal || isConsulta || isSMTC">
           <ngb-panel title="Colaboradores">
              <ng-template ngbPanelContent>
                <app-colaborador [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"></app-colaborador>
              </ng-template>
            </ngb-panel>
            <ngb-panel title="Prestadores de Serviço Terceirizados">
              <ng-template ngbPanelContent>
                <app-prestadorServTer [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"></app-prestadorServTer>
              </ng-template>
            </ngb-panel>
          </div>
            <div *ngIf="!isPortal">
              <ngb-panel id="responsavel" title="Responsável Técnico">
                <ng-template ngbPanelContent>
                  <form (ngSubmit)="openAtualizarParceria(contentAtualizarParceria)" #edicaoParceira="ngForm" novalidate>
                    <div class="row" style="margin-top: 30px">
                      <div class="col-md-4">
                        <label for="responsavel ">Dirigente / Responsável:</label>
                        <input [disabled]="(isPortal || isFiscal || isAuditorExterno)" id="responsavel" name="responsavel" #responsavel="ngModel" class="form-control" style="width: 100%" type="text " [(ngModel)]="parceriaInfo.execResponsavel" required>
                      </div>
                      <div class="col-md-4">
                        <label for="telefone ">Telefone:</label>
                        <input [disabled]="(isPortal || isFiscal || isAuditorExterno)" id="telefone " name="telefone" #telefone="ngModel" class="form-control" style="width: 100%" type="text " [(ngModel)]="parceriaInfo.execTelefone" required>
                      </div>
                      <div class="col-md-4">
                        <label for="email ">Email:</label>

                        <input [disabled]="(isPortal || isFiscal || isAuditorExterno)" id="email " class="form-control " name="email" #email="ngModel" type="text " style="width: 100%" id="email" name="email" #email="ngModel" [(ngModel)]="parceriaInfo.execEmail " required emailvalidator>
                      </div>
                    </div>
                    <br>
                    <div>
                      <button type="submit" class="btn btn-primary btn-info pull-right" type="button " [disabled]="!edicaoParceira.form.valid" [hidden]="(isPortal || isFiscal || isAuditorExterno)">Salvar</button>
                    </div>
                    <br>
                    <br>
                    <hr>
                  </form>
                  <ng-template #contentAtualizarParceria let-c="close" let-d="dismiss">
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Atualização de Dirigente/Responsável da parceria</h4>
                      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                    </div>
                    <div class="modal-body">
                      <p>Tem certeza que deseja atualizar o dados de dirigente/responsável da parceria?</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Fechar</button>
                      <button type="button" class="btn btn-secondary" (click)="atualizaParceria()">Atualizar</button>
                    </div>

                  </ng-template>

                </ng-template>
              </ngb-panel>
            </div>
            <ngb-panel id="notificacoes-apontamentos" *ngIf="!isPortal" title="Notificações de Apontamentos">
              <ng-template ngbPanelContent>
                <app-notificacoes [parceriaInfo]="parceriaInfo" [pesquisaData]="pesquisaData" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-notificacoes>
              </ng-template>
            </ngb-panel>
            <!-- //  StandBy pelo usuário
                            <ngb-panel id="Frequencia" *ngIf="!isPortal && !isFiscal && !isSMTC && !isConsulta">
                            <ng-template ngbPanelTitle>Frequência dos Beneficiários
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-frequencia-beneficiario [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"> </app-frequencia-beneficiario>
                            </ng-template>
                        </ngb-panel>
                    -->

          </ngb-accordion>
        </div>
      </tab>
      <!-- //  StandBy pelo usuário
                <tab class="nav-item" title="Objeto" *ngIf="isPortal  || isFiscal || isSMTC || isConsulta">
                <BR>
                <div class="container">
                    <ngb-accordion #acc="ngbAccordion" (panelChange)="acordiaoChange($event)">
                        <ngb-panel id="dadosAtendimento">
                            <ng-template ngbPanelTitle>Dados de Atendimento
                               // Possui a mesma chamada no cadastro de beneficiários
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-vinculos-beneficiario [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"> </app-vinculos-beneficiario>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="Frequencia">
                            <ng-template ngbPanelTitle>Frequência dos Beneficiários
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-frequencia-beneficiario [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"> </app-frequencia-beneficiario>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </tab>
            <tab class="nav-item" title="Trava" *ngIf="!isPortal && (isFiscal || isSMTC || isConsulta)">
                <BR>
                <div class="container">
                    <ngb-accordion #acc="ngbAccordion" (panelChange)="acordiaoChange($event)">
                        <div *ngIf="isFiscal">
                            <ngb-panel id="travaObk" title="Autorização Especial (desbloqueio de trava)">
                                <ng-template ngbPanelContent>
                                    <app-excecao-trava-Frequencia [parceriaInfo]="parceriaInfo"></app-excecao-trava-Frequencia>
                                </ng-template>
                            </ngb-panel>
                        </div>
                    </ngb-accordion>
                </div>
            </tab>
            -->
    </tabset>
  </div>
</div>
