<div class="infoGeral container" *ngIf="prestadores != undefined">
  <div *ngIf="naoTemPermissao$ | async">
    <h3 class="mt-5 mb-3">Sem Permissão</h3>
    <p>
      No momento você não tem acesso integral ao Sistema de Gestão de Parcerias.
      Solicitamos que entre em contato com o órgão responsável pela parceria.
    </p>
  </div>
  <div *ngIf="loading$ | async">
    <div class="loading-overlay"></div>
    <div class="loading-info">
      <i class="fa fa-spinner fa-4x fa-spin"></i>
      <div id="carregando">Aguarde</div>
    </div>
  </div>
  <BR />
  <div class="row align-items-end">
    <div *ngIf="temPermissao$ | async" [hidden]="cnpjs.length == 0" class="col-md-10">
      <div *ngIf="!isFiscal && !isConsulta">
        <label for="">Selecione a OSC</label>
        <div class="select-wrapper">
          <select name="selCnpj" #selCnpj="ngModel" class="form-control custom-select" [ngModel]="cnpjs"
            (change)="setCnpj($event)" required>
            <option *ngFor="let cnpj of cnpjs" [value]="cnpj[0]">
              {{ aplicaMascaraCNPJ(cnpj[0]) }} - {{ cnpj[1] }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="prestadores.length == 0 && cnpj !== undefined" class="col-md-12">
      <span class="mensagem-vazio">Nenhum prestador de serviços foi encontrado.</span>
    </div>
    <div *ngIf="prestadores.length > 0" class="col-md-12">
      <br />
      <b>Prestadores de Serviços</b>
      <table class="table table-striped" style="margin-top: 30px" id="tabelaEmpreSerTerc" [mfData]="prestadores"
        #mtPrestadores="mfDataTable" [mfRowsOnPage]="10">
        <thead>
          <tr>
            <th data-html2canvas-ignore="true"></th>
            <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
            <th>Nome</th>
            <th>CPF/CNPJ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of mtPrestadores.data; let j = index">
            <td class="col-auto" data-html2canvas-ignore="true">
              <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true"
                (click)="openEdicao(contentEdicao, data)"></i>
              <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true"
                (click)="openEdicao(contentEdicao, data)"></i>
            </td>
            <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
              <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                (click)="openExcluir(contentExcluir, data)"></i>
            </td>
            <td>{{ data.empresaRazaoSocial }}</td>
            <td>{{ aplicaMascaraCpfCnpj(data) }}</td>
          </tr>
        </tbody>
        <tfoot data-html2canvas-ignore="true ">
          <tr>
            <td colspan="12 ">
              <div *ngIf="prestadores.length > 10" class="resultPorPagina">
                Resultados por página:
              </div>
              <mfBootstrapPaginator [rowsOnPageSet]="[10, 20, 30]"></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div *ngIf="!disableCampos()" class="col-md-2">
      <button (click)="openEdicao(contentEdicao, undefined)" id="button-insert_doc " class="btn btn-primary btn-info"
        type="button " title="Adicionar novo" [hidden]="!enableEditarIcone()">
        <i class="fa fa-plus"><span class="texto-botao">Adicionar novo</span>
        </i>
      </button>
    </div>
  </div>
  <div *ngIf="disableCampos() && cnpj !== undefined && prestadores.length > 0">
    <button (click)="relatorioprestadores('PDF')" id="button-lista_pdf " class="btn btn-primary btn-info" type="button "
      title="Baixar lista em PDF">
      <span class="texto-botao">Baixar lista em PDF</span>
    </button>
    &nbsp;&nbsp;&nbsp;
    <button (click)="relatorioprestadores('XLS')" id="button-lista_xls " class="btn btn-primary btn-info" type="button "
      title="Baixar lista em Excel">
      <span class="texto-botao">Baixar lista em Excel</span>
    </button>
  </div>
  <ng-template #contentEdicao let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">
        Prestador
        <span [hidden]="isNotSelectedOrNotPjPf('PF') | async" id="modal-basic-title">
          Pessoa Jurídica (Empresa)</span>
      </h4>
      <h4 class="modal-title">
        <span [hidden]="isNotSelectedOrNotPjPf('PJ') | async" id="modal-basic-title">
          Pessoa Física</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #edicao="ngForm" novalidate>
      <div class="modal-body" *ngIf="prestadorSend">
        <div class="form-row mt-1" *ngIf="!isEdicao">
          <div class="form-group col-md-9 mb-0">
            <div class="form-check form-check-inline">
              <div>
                O prestador de serviço é:
                <span class="campoObrigatorio">*</span>
                <input type="radio" class="form-check-input" [name]="isCPF" id="F" value="F"
                  (change)="updatePessoa($event)" style="margin-left: 20px" />
                <label for="F" class="form-check-label mr-4">Pessoa Física</label>
                <input type="radio" class="form-check-input" [name]="isCPF" id="J" value="J"
                  (change)="updatePessoa($event)" />
                <label for="J" class="form-check-label">Pessoa Jurídica</label>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="
            (isNotSelectedOrNotPjPf('PJ') | async) &&
            (isNotSelectedOrNotPjPf('PF') | async)
          ">
          <br />
          <hr class="class-hr-espaco" />
          <h5>Identificação do Contratado</h5>
          <hr class="class-hr-espaco" />
        </div>
        <div class="form-row">
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="cpf">CPF</label>
            <span [hidden]="cpf.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="cpf" name="cpf" #cpf="ngModel"
              class="form-control" type="text " [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaCnpjCpf" [value]="prestadorSend.empresaCnpjCpf"
              (blur)="selectCpfCnpj(1)" class="form-control" placeholder="informe o CPF" [textMask]="mascaraCPF"
              required />
            <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true " (click)="
                prestadorSend.empresaCnpjCpf = undefined;
                edicao.form.valid = false
              " title="limpar "></i>
          </div>
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="rg">Nº Identidade (RG)</label>
            <span [hidden]="rg.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="rg" name="rg" #rg="ngModel" class="form-control"
              type="text" [ngModelOptions]="{ standalone: true }" [(ngModel)]="prestadorSend.numeroRG"
              [value]="prestadorSend.numeroRG" required />
          </div>
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="orgaoEmissor">Órgão Emissor</label>
            <span [hidden]="orgaoEmissor.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="orgaoEmissor" name="orgaoEmissor"
              #orgaoEmissor="ngModel" class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.orgaoEmissor" [value]="prestadorSend.orgaoEmissor" required />
          </div>
          <div class="form-group col-md-3" [hidden]="isNotSelectedOrNotPjPf('PF') | async">
            <label for="cnpj">CNPJ</label>
            <span [hidden]="cnpj.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="cnpj" name="cnpj" #cnpj="ngModel"
              class="form-control" type="text " [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaCnpjCpf" [value]="prestadorSend.empresaCnpjCpf"
              (blur)="selectCpfCnpj(2)" class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ"
              required />
            <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true " (click)="
                prestadorSend.empresaCnpjCpf = undefined;
                edicao.form.valid = false
              " title="limpar "></i>
          </div>
          <div class="form-group col-lg-9 col-md-12" [hidden]="isNotSelectedOrNotPjPf() | async">
            <label [hidden]="isNotSelectedOrNotPjPf('PJ') | async" for="nome">Nome</label>
            <label [hidden]="isNotSelectedOrNotPjPf('PF') | async" for="nome">Razão Social</label>
            <input [disabled]="isBloquearEdicaoPF" style="width: 100%" id="nome" name="nome" #nome="ngModel"
              class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaRazaoSocial" [value]="prestadorSend.empresaRazaoSocial" required />
          </div>
          <div class="form-group col-md-12" [hidden]="isNotSelectedOrNotPjPf('PF') | async">
            <label for="nomeFant">Nome Fantasia</label>
            <input [disabled]="true" style="width: 100%" id="nomeFant" name="nomeFant" #nomeFant="ngModel"
              class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaNomeFantasia" [value]="prestadorSend.empresaNomeFantasia" required />
          </div>
          <div class="form-group col-md-9" [hidden]="isNotSelectedOrNotPjPf() | async">
            <label for="endereco">Endereço</label>
            <textarea [disabled]="isBloquearEdicaoPF" ng-d style="width: 100%; height: 38px" id="endereco"
              class="form-control" #endereco="ngModel" [(ngModel)]="prestadorSend.empresaEndereco"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateTextArea($event.target.value)">{{ prestadorSend.empresaEndereco }}</textarea>
          </div>
          <div class="form-group col-md-3" [hidden]="isNotSelectedOrNotPjPf() | async">
            <label for="cep">Cep</label>
            <input [disabled]="isBloquearEdicaoPF" style="width: 100%" id="cep" name="cep" #cep="ngModel"
              [textMask]="mascaraCEP" class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaCep" [value]="prestadorSend.empresaCep" required />
          </div>
          <div class="form-group col-md-3" [hidden]="isNotSelectedOrNotPjPf('PF') | async">
            <label for="cep">Status</label>
            <input [disabled]="true" style="width: 100%" id="st" name="st" #st="ngModel" class="form-control"
              type="text" [ngModelOptions]="{ standalone: true }" [(ngModel)]="prestadorSend.empresaStatus"
              [value]="prestadorSend.empresaStatus" />
          </div>
          <div class="form-group col-md-9" [hidden]="isNotSelectedOrNotPjPf('PF') | async">
            <label for="atividade">Atividade Principal</label>
            <input [disabled]="true" style="width: 100%" id="atividade" name="atividade" #atividade="ngModel"
              class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.empresaAtividadePrincipal" [value]="prestadorSend.empresaAtividadePrincipal" />
          </div>
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="rgps">Nº RGPS</label>
            <span [hidden]="rgps.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="rgps" name="rgps" #rgps="ngModel"
              class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.numeroInscricaoRGPS" [value]="prestadorSend.numeroInscricaoRGPS" required />
          </div>

          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="empresaInscricaoMunicipal">Inscrição Municipal</label>
            <input [disabled]="disableCampos()" style="width: 100%" id="empresaInscricaoMunicipal"
              name="empresaInscricaoMunicipal" #empresaInscricaoMunicipal="ngModel" class="form-control" type="text"
              [ngModelOptions]="{ standalone: true }" [(ngModel)]="prestadorSend.empresaInscricaoMunicipal"
              [value]="prestadorSend.empresaInscricaoMunicipal" />
          </div>
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="empresaInscricaoMunicipal">Nº Registro Profissional</label>
            <span [hidden]="numeroRegistroProfissional.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="numeroRegistroProfissional"
              name="numeroRegistroProfissional" #numeroRegistroProfissional="ngModel" class="form-control" type="text"
              [ngModelOptions]="{ standalone: true }" [(ngModel)]="prestadorSend.numeroRegistroProfissional"
              [value]="prestadorSend.numeroRegistroProfissional" required />
          </div>
          <div class="form-group col-lg-2 col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="orgaoFiscalizador">Órgão Fiscalizador</label>
            <span [hidden]="orgaoFiscalizador.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="orgaoFiscalizador" name="orgaoFiscalizador"
              #orgaoFiscalizador="ngModel" class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.orgaoFiscalizador" [value]="prestadorSend.orgaoFiscalizador" required />
          </div>
          <div class="form-group col-lg-4 col-md-6" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label for="numeroCodigoBrasileiroOcupacao">Nº Código Brasileiro Ocupação (CBO)</label>
            <span [hidden]="numeroCodigoBrasileiroOcupacao.valid" class="campoObrigatorio">*</span>
            <input [disabled]="disableCampos()" style="width: 100%" id="numeroCodigoBrasileiroOcupacao"
              name="numeroCodigoBrasileiroOcupacao" #numeroCodigoBrasileiroOcupacao="ngModel" class="form-control"
              type="text" [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="prestadorSend.numeroCodigoBrasileiroOcupacao"
              [value]="prestadorSend.numeroCodigoBrasileiroOcupacao" required />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-8" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <label>Selecione um serviço da lista para reemitir o Recibo ou adicione um novo clicando no botão ao lado</label>
            <ng-select style="width: 100%" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" name="servSel"
              class="form-control" [ngbTooltip]="tipContent" virtualScroll="true"
              [items]="listaServicoPrestadoOption" bindLabel="name" bindValue="id"
              (change)="updateServicoSelecionado($event)" required aria-placeholder="selecione abaixo">
            </ng-select>
          </div>
          <div class="form-group col-md-3" style="margin-top: 38px; margin-left: 20px; font-weight: bold"
            [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            <span> Ou </span>

            <button class="btn btn-primary btn-peq" style="margin-left: 30px" (click)="adicionaNovoServico()"
              *ngIf="!disableCampos()">
              Adicionar Serviço
            </button>
          </div>
          <br/><br/>
        </div>
        <br [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />

        <div [hidden]="servicoPrestadoSelecionado.id == 0">
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <h5 [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            Identificação do Serviço Prestado
            <span *ngIf="!verificaServicosPrestados()" class="campoObrigatorio">*</span>
          </h5>
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <div class="form-row">
            <div class="col-md-5">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="dataInicio">Data Início</label>
                  <span [hidden]="dataInicio.valid" class="campoObrigatorio">*</span>
                  <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" maxlength="10" class="form-control"
                    name="dataInicio" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="
                      servicoPrestadoSelecionado.inicioExecucaoDataFormat
                    " #dataInicio="ngModel" [(ngModel)]="
                      servicoPrestadoSelecionado.inicioExecucaoDataFormat
                    " required ngbDatepicker #d="ngbDatepicker" />
                  <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-4">
                  <label for="dataFim">Data Fim</label>
                  <span [hidden]="dataFim.valid" class="campoObrigatorio">*</span>
                  <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" maxlength="10" class="form-control"
                    name="dataFim" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="
                      servicoPrestadoSelecionado.fimExecucaoDataFormat
                    " #dataFim="ngModel" [(ngModel)]="
                      servicoPrestadoSelecionado.fimExecucaoDataFormat
                    " required ngbDatepicker #dF="ngbDatepicker" />
                  <i class="fa fa-calendar icon" (click)="dF.toggle()" title="calendário"></i>
                </div>
                <div class="col-md-4 form-group">
                  <label for="tipoAtividade">Tipo Atividade</label>
                  <span [hidden]="tipoAtividade.valid" class="campoObrigatorio">*</span>
                  <div class="select-wrapper" style="width: 100%">
                    <select [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" [ngModel]="servicoPrestadoSelecionado.tipoAtividade"
                      [ngModelOptions]="{ standalone: true }" class="form-control" #tipoAtividade="ngModel"
                      name="tipoAtividade" (change)="
                        updateTipoAtividade($event, servicoPrestadoSelecionado)
                      " required>
                      <option [value]="option.id" *ngFor="let option of this.utils.tipoAtividade">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 form-group" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="tipoServ">Tipo de Serviço</label>
              <span [hidden]="tipoServ.valid" class="campoObrigatorio">*</span>
              <textarea [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" ng-d style="width: 100%;height: 38px;" id="tipoServ"
                class="form-control" #tipoServ="ngModel" [(ngModel)]="servicoPrestadoSelecionado.descricaoServico"
                [ngModelOptions]="{ standalone: true }" (change)="
                  updateTextAreaDescricaoServico(
                    $event.target.value,
                    servicoPrestadoSelecionado.descricaoServico
                  )
                " required>{{ servicoPrestadoSelecionado.descricaoServico }}</textarea>
            </div>
          </div>
          <br>
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <h5 [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            Identificação do Contratante
          </h5>
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <div class="form-row">
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="cnpjOrgao">CNPJ</label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="cnpjOsc" name="cnpjOsc" #cnpjOsc="ngModel"
                class="form-control" type="text " [ngModelOptions]="{ standalone: true }" (blur)="selectOscCnpj(2)"
                [(ngModel)]="servicoPrestadoSelecionado.cnpjOsc" [value]="servicoPrestadoSelecionado.cnpjOsc"
                class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ" required />
            </div>

            <div class="form-group col-md-5" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label [hidden]="isNotSelectedOrNotPjPf('PJ') | async" for="nomeOSC">Razão Social Parceira</label>
              <input [disabled]="true" style="width: 100%" id="nomeOSC" name="nomeOSC" #nomeOSC="ngModel"
                class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="servicoPrestadoSelecionado.razaoSocialOsc"
                [value]="servicoPrestadoSelecionado.razaoSocialOsc" required />
            </div>
            <div class="form-group col-md-5" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="nomeContratante">Responsável Contratação</label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1"
              style="width: 100%" id="nomeContratante" class="form-control" name="nomeContratante"
                #nomeContratante="ngModel" type="text" [(ngModel)]="servicoPrestadoSelecionado.responsavelContratacao"
                required />
            </div>

            <div class="form-group col-md-9" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="enderecoOsc">Endereço</label>
              <textarea [disabled]="true" ng-d style="width: 100%; height: 38px" id="enderecoOsc" class="form-control"
                #enderecoOsc="ngModel" [(ngModel)]="servicoPrestadoSelecionado.enderecoOsc"
                [ngModelOptions]="{ standalone: true }"
                (change)="updateTextArea($event.target.value)">{{ servicoPrestadoSelecionado.enderecoOsc }}</textarea>
            </div>
            <div class="form-group col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="cepOsc">Cep</label>
              <input [disabled]="true" style="width: 100%" id="cepOsc" name="cepOsc" #cepOsc="ngModel"
                [textMask]="mascaraCEP" class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="servicoPrestadoSelecionado.cepOsc" [value]="servicoPrestadoSelecionado.cepOsc" required />
            </div>
          </div>
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <h5 [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
            Dados Financeiros
          </h5>
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PJ') | async" />
          <div class="form-row form-inline align-items-end">
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="valorBruto">Valor Bruto do Serviço </label>
              <span [hidden]="valorBruto.valid" class="campoObrigatorio">*</span>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="valorBruto" name="valorBruto"
                #valorBruto="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.valorBrutoServico"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
            </div>
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="baseCalcIRRF">Base de Cálculo IRRF</label>

              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="baseCalcIRRF" name="baseCalcIRRF"
                #baseCalcIRRF="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.baseCalcIRRF"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero required />
            </div>
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="retencaoIRRF">Retenção IRRF<span [hidden]="retencaoIRRF.valid"
                  class="campoObrigatorio">*</span></label>

              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="retencaoIRRF" name="retencaoIRRF"
                #retencaoIRRF="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.retencaoIRRF"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
            </div>

            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="baseCalcRGPS">Base de Cálculo Retenção Previdência Social</label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="baseCalcRGPS" name="baseCalcRGPS"
                #baseCalcRGPS="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.baseCalcRGPS"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
            </div>

            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="retencaoRGPS"> Retenção Previdência Social</label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="retencaoRGPS" name="retencaoRGPS"
                #retencaoRGPS="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.retencaoRGPS"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
              
            </div>
            </div>
          <div class="form-row form-inline align-items-end">
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="outros">Outras Retenções </label>
              <span [hidden]="outros.valid" class="campoObrigatorio">*</span>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="outros" name="outros" #outros="ngModel"
                class="form-control" type="text" currencyMask [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="servicoPrestadoSelecionado.outros"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
              <!--valorBruto.valid-->
            </div>
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="valorLiquido">Valor Líquido </label>
              <span [hidden]="valorLiquido.valid" class="campoObrigatorio">*</span>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="valorLiquido" name="valorLiquido"
                #valorLiquido="ngModel" class="form-control" type="text" currencyMask
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="servicoPrestadoSelecionado.valorLiquido"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero required />
              <!--valorBruto.valid-->
            </div>
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="numeroDependentes">Nº de dependentes </label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1" style="width: 100%" id="numeroDependentes" name="numeroDependentes"
                #numeroDependentes="ngModel" class="form-control" type="text" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="servicoPrestadoSelecionado.numeroDependentes" validaInputZero />
            </div>
            <div class="form-group col-md-2" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label style="
              width: 100%;
              text-align: left;
              display: inline-block;
              padding: 10px 0 20px 0;
          "> Comprovante de retenção ao RGPS
                <span class="campoObrigatorio">*</span></label>
                <fieldset class="form-check form-check-inline" [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1">
                  <div class="form-check form-check-inline">
                    <input  
                    class="form-check-input" type="radio" [name]="comprovanteRGPS"  [value]="true"
                      #comprovanteRGPS="ngModel" [(ngModel)]="servicoPrestadoSelecionado.comprovanteRGPS">
                    <label class="form-check-label" for="true">sim</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [name]="comprovanteRGPS"  [value]="false"
                      #comprovanteRGPS="ngModel" [(ngModel)]="servicoPrestadoSelecionado.comprovanteRGPS">
                    <label class="form-check-label" for="false">não</label>
                  </div>
                </fieldset>
            </div>

          </div>
          <div class="form-row form-inline align-items-end">
            

            <div class="form-group col-md-3" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <label for="Banco">Banco</label>
              <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1"
              style="width: 100%" id="Banco" class="form-control" name="Banco" #Banco="ngModel" type="text"
                [(ngModel)]="servicoPrestadoSelecionado.nomeBanco" required />
            </div>
            <div class="col-md-3">
              <div class="form-row form-inline">
                <div class="form-group col-md-6" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
                  <label for="agencia">Agência</label>
                  <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1"
                  style="width: 100%" id="agencia" class="form-control" name="agencia" #agencia="ngModel"
                    type="text" [(ngModel)]="servicoPrestadoSelecionado.agenciaConta" required />
                </div>

                <div class="form-group col-md-6" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
                  <label for="conta">Nº da Conta</label>
                  <input [disabled]="disableCampos() || servicoPrestadoSelecionado.id !=-1"
                  style="width: 100%" id="conta" class="form-control" name="conta" #conta="ngModel" type="text"
                    [(ngModel)]="servicoPrestadoSelecionado.numeroConta" required />
                </div>
              </div>
            </div>
            <div class="col-md-4 gap-12" [hidden]="isNotSelectedOrNotPjPf('PJ') | async">
              <button [disabled]="noServicoId$ | async" class="btn btn-primary"
                [hidden]="isNotSelectedOrNotPjPf('PJ') | async" (click)="
                  reciboPagamento(prestadorSend, servicoPrestadoSelecionado.id)
                ">
                <i class="fa fa-download" style="color: white" aria-hidden="true"></i>
                Baixar Recibo
              </button>

              <button [disabled]="noServicoId$ | async" class="btn btn-primary"
                [hidden]="isNotSelectedOrNotPjPf('PJ') | async" (click)="
                  openExcluirServ(
                    contentExcluirServ,
                    servicoPrestadoSelecionado
                  )
                ">
                <i class="fa fa-download" style="color: white" aria-hidden="true"></i>
                Excluir Serviço
              </button>
            </div>
          </div>

          <br />
        </div>
        <br />

        <div class="form-group cargaHoraSemana" [hidden]="isNotSelectedOrNotPjPf('PF') | async">
          <hr class="class-hr-espaco" [hidden]="isNotSelectedOrNotPjPf('PF') | async" />
          <h5 [hidden]="isNotSelectedOrNotPjPf('PF') | async">
            Identificação do(s) Contratos
            <span *ngIf="!verificaContratosPJ()" class="campoObrigatorio">*</span>
          </h5>

          <table class="table tabela-inputs tabela-tabela tabela-pj" name="contratosPJ" id="contratosPJ"
            [mfData]="prestadorSend.contratosPj" #mtContratosPJ="mfDataTable">
            <thead>
              <tr>
                <th *ngIf="!disableCampos()"></th>
                <th style="width: 120px;">Número<br> contrato</th>
                <th style="width: 120px;">Vigência contrato<br>Início<span *ngIf="!verificaContratosPJ()" class="campoObrigatorio">*</span>/Fim
                 
                </th>
                <th>Descrição<br>tipo de serviço
                  <span *ngIf="!verificaContratosPJ()" class="campoObrigatorio">*</span>
                </th>
                <th style="text-align: right;">Valor<br>mensal</th>
                <th style="width: 120px;">Vigência $ mensal<br>Início<span *ngIf="!verificaContratosPJ()" class="campoObrigatorio">*</span>/Fim
                  
                </th>
                <th>Tipo<br>atividade</th>
                <th>Anexos</th>
              </tr>
            </thead>
            <tbody>
              <tr style="vertical-align: middle" *ngFor="let dataCont of mtContratosPJ.data">
                <td *ngIf="!disableCampos()" [hidden]="dataCont == null"><i ngbTooltip="apagar contrato" class="fa fa-trash-o" style="font-size: 18px;margin-top: 28px;cursor: pointer;padding-right: 4px;" aria-hidden="true"
                    (click)="excluirContratoPJ(dataCont)"></i>
                </td>
                <td>
                  <input style="width: 100%; height: 70px;" class="form-control input-tabela" name="nContrato" type="text"
                    [ngModelOptions]="{ standalone: true }" [(ngModel)]="dataCont.numeroContrato" validaInputZero
                    [textMask]="mascaraNroDoc" [disabled]="disableCampos()" required />
                </td>
                <td>
                 <div>
                  <input style="width: 100%" class="form-control input-tabela" name="inicioVigenciaContrato"
                  maxlength="10" placeholder="dd/mm/aaaa" appMaskInput
                  [appMaskValue]="dataCont.inicioVigenciaContratoDataFormat"
                  [(ngModel)]="dataCont.inicioVigenciaContratoDataFormat" ngbDatepicker #divc="ngbDatepicker"
                  [ngModelOptions]="{ standalone: true }" [disabled]="disableCampos()" required />
                <i class="fa fa-calendar icon" (click)="divc.toggle()" title="calendário"></i>
                 </div>
                 <div>
                  <input style="width: 100%" class="form-control input-tabela" name="fimVigenciaContrato" maxlength="10"
                  placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="dataCont.fimVigenciaContratoDataFormat"
                  [(ngModel)]="dataCont.fimVigenciaContratoDataFormat" ngbDatepicker #dfvc="ngbDatepicker"
                  [ngModelOptions]="{ standalone: true }" [disabled]="disableCampos()" required />
                <i class="fa fa-calendar icon" (click)="dfvc.toggle()" title="calendário"></i>
                 </div>
                </td>
                <td>
                  <textarea [disabled]="disableCampos()" style="height: 70px; margin: 0;"
                    class="form-control" id="tipoServOCPU" [(ngModel)]="dataCont.tipoDeServico"
                    [ngModelOptions]="{ standalone: true }" (change)="
                      updateOcupacaoLaboralPJ($event.target.value, dataCont)
                    " required>{{ dataCont.tipoDeServico }}</textarea>
                </td>
                <td>
                  <input style="width: 100%; height: 70px;" class="form-control input-tabela" name="valorMensalVigente"
                    [(ngModel)]="dataCont.valorMensalVigente" [ngModelOptions]="{ standalone: true }" type="text"
                    currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero
                    [disabled]="disableCampos()" required />
                </td>
                <td>
                 <div>
                  <input style="width: 100%" class="form-control input-tabela" name="inicioVigenciaValorMensal"
                  maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="
                    dataCont.inicioVigenciaValorMensalDataFormat
                  " [(ngModel)]="dataCont.inicioVigenciaValorMensalDataFormat" ngbDatepicker #divvm="ngbDatepicker"
                  [ngModelOptions]="{ standalone: true }" [disabled]="disableCampos()" required />
                <i class="fa fa-calendar icon" (click)="divvm.toggle()" title="calendário"></i>
                 </div>
                 <div>
                  <input style="width: 100%" class="form-control input-tabela" name="fimVigenciaValorMensal"
                  maxlength="10" placeholder="dd/mm/aaaa" appMaskInput
                  [appMaskValue]="dataCont.fimVigenciaValorMensalDataFormat"
                  [(ngModel)]="dataCont.fimVigenciaValorMensalDataFormat" ngbDatepicker #dfvvm="ngbDatepicker"
                  [ngModelOptions]="{ standalone: true }" [disabled]="disableCampos()" required />
                <i class="fa fa-calendar icon" (click)="dfvvm.toggle()" title="calendário"></i>
                 </div>
                </td>
                <td>
                  <select [disabled]="disableCampos()" style="height: 70px; margin: 0; font-size: 14px;"
                    [ngModel]="dataCont.tipoAtividade" [ngModelOptions]="{ standalone: true }" class="form-control "
                    (change)="updateTipoAtividade($event, dataCont)" required>
                    <option [value]="option.id" *ngFor="let option of this.utils.tipoAtividade">
                      {{ option.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 10px !important;" class="coluna-anexos">
                  <div class="upload-tabela" [hidden]="
          dataCont.contratoNome != null ||
          disableCampos() ||
          (isNotSelectedOrNotPjPf('PF') | async)
        ">
                    <label for="anexo">Contrato</label>
                    <input type="file" multiple name="anexo" id="anexo" class="form-control-upload"
                      accept=".pdf, .gif, .jpg, .jpeg, .png" (change)="onFileChanged($event, dataCont, 0)" />
                  </div>
                  <div class="arquivo-anexo" [hidden]="dataCont.contratoNome == null" ngbTooltip="{{ dataCont.contratoNome }}">
                    <label for="">Contrato: </label>
                    <div (click)="
              downloadPrestadorServTerDocumento(
                dataCont.prestadorPJTerceiroId,
                dataCont.id,
                dataCont.contratoNome,
                0
              )
            ">{{ dataCont.contratoNome }}
                    </div>
                    <i *ngIf="!disableCampos()" class="fa fa-trash" aria-hidden="true" (click)="
                      dataCont.contratoArquivo = undefined;
                      dataCont.contratoTipo = undefined;
                      dataCont.contratoNome = null
                    " alt="download"></i>
                  </div>


                  <div class="upload-tabela" [hidden]="
dataCont.orcamento1Nome != null ||
disableCampos() ||
(isNotSelectedOrNotPjPf('PF') | async)
">
                    <label for="anexo">Orçamento 1</label>
                    <input type="file" name="anexo" id="anexo" class="form-control-upload"
                      style="vertical-align: text-top" accept=".pdf, .gif, .jpg, .jpeg, .png"
                      (change)="onFileChanged($event, dataCont, 1)" />
                  </div>
                  <div class="arquivo-anexo" [hidden]="dataCont.orcamento1Nome == null"  ngbTooltip="{{ dataCont.orcamento1Nome }}">
                    <label for="">Orçamento 1:</label>
                    <div (click)="
  downloadPrestadorServTerDocumento(
    dataCont.prestadorPJTerceiroId,
    dataCont.id,
    dataCont.orcamento1Nome,
    1
  )
">{{ dataCont.orcamento1Nome }}</div>
                    <i *ngIf="!disableCampos()" class="fa fa-trash" aria-hidden="true" style="vertical-align: text-top"
                      (click)="
  dataCont.orcamento1Arquivo = undefined;
  dataCont.orcamento1Tipo = undefined;
  dataCont.orcamento1Nome = null
" alt="download"></i>
                  </div>
                  <div class="upload-tabela" [hidden]="
dataCont.orcamento2Nome != null ||
disableCampos() ||
(isNotSelectedOrNotPjPf('PF') | async)
">
                    <label for="anexo">Orçamento 2</label>
                    <input type="file" name="anexo" id="anexo" class="form-control-upload" style="margin-top: -0.5em"
                      accept=".pdf, .gif, .jpg, .jpeg, .png" (change)="onFileChanged($event, dataCont, 2)" />
                  </div>
                  <div class="arquivo-anexo" [hidden]="dataCont.orcamento2Nome == null" ngbTooltip="{{ dataCont.orcamento2Nome }}">
                    <label for="">Orçamento 2:</label>
                    <div (click)="
  downloadPrestadorServTerDocumento(
    dataCont.prestadorPJTerceiroId,
    dataCont.id,
    dataCont.orcamento2Nome,
    2
  )
">{{ dataCont.orcamento2Nome }}</div>
                    <i *ngIf="!disableCampos()" class="fa fa-trash" aria-hidden="true" (click)="
  dataCont.orcamento2Arquivo = undefined;
  dataCont.orcamento2Tipo = undefined;
  dataCont.orcamento2Nome = null
" alt="download"></i>
                  </div>
                  <div class="upload-tabela" [hidden]="
                          dataCont.orcamento3Nome != null ||
                          disableCampos() ||
                          (isNotSelectedOrNotPjPf('PF') | async)
                        ">
                    <label for="anexo">Orçamento 3</label>
                    <input type="file" name="anexo" id="anexo" class="form-control-upload"
                      accept=".pdf, .gif, .jpg, .jpeg, .png" (change)="onFileChanged($event, dataCont, 3)" />
                  </div>
                  <div class="arquivo-anexo" [hidden]="dataCont.orcamento3Nome == null" ngbTooltip="{{ dataCont.orcamento3Nome }}">
                    <label for="">Orçamento 3:</label>
                    <div (click)="
                            downloadPrestadorServTerDocumento(
                              dataCont.prestadorPJTerceiroId,
                              dataCont.id,
                              dataCont.orcamento3Nome,
                              3
                            )
                          ">{{ dataCont.orcamento3Nome }}</div>
                    <i *ngIf="!disableCampos()" class="fa fa-trash" aria-hidden="true" (click)="
                            dataCont.orcamento3Arquivo = undefined;
                            dataCont.orcamento3Tipo = undefined;
                            dataCont.orcamento3Nome = null
                          " alt="download"></i>
                  </div>

                </td>
              </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true">
              <tr>
                <td colspan="10">
                  <button class="btn btn-primary btn-peq" (click)="adicionarContratoPJ()" *ngIf="!disableCampos()">
                    Incluir
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div *ngIf="!visualizarBotao()" class="modal-footer" style="width: 100%">
          <button type="button" class="btn btn-default" (click)="
              c('Close click');
              setPrestadorServTer(undefined);
              isNotSelectedOrNotPjPf('PJ')
            ">
            Cancelar
          </button>
          <button type="button" class="btn btn-primary" ng-disabled="(!edicao.form.valid && !inValidCpf)"
            (click)="salvaPrestador()">
            Salvar
          </button>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Excluir Prestador</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Tem certeza que deseja excluir o prestador de serviço
        <b>{{ prestadorSend.empresaRazaoSocial }}</b>?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">
        Fechar
      </button>
      <button type="button" class="btn btn-primary" style="margin-top: -2px" (click)="excluirPrestador(prestadorSend)"
        title="Excluir">
        Excluir
      </button>
    </div>
  </ng-template>

  <ng-template #contentExcluirServ let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Excluir Serviço Prestado
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Tem certeza que deseja excluir o serviço
        <b>{{
          servicoPrestadoSelecionado.inicioExecucao | date : "dd/MM/yyyy"
          }}
          a {{ servicoPrestadoSelecionado.fimExecucao | date : "dd/MM/yyyy" }} -
          {{ servicoPrestadoSelecionado.descricaoServico }}
        </b>
        do prestador de serviço <b>{{ prestadorSend.empresaRazaoSocial }}</b>?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">
        Fechar
      </button>
      <button type="button" class="btn btn-primary" style="margin-top: -2px"
        (click)="excluirServicoPrestado(servicoPrestadoSelecionado)" title="Excluir">
        Excluir
      </button>
    </div>
  </ng-template>
</div>