import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
/* App Root */
import { CoreModule } from './@core/core.module';
import { SharedModule } from './@shared/shared.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExibeParceriaPorUsuarioComponent } from './modulo2/exibeParceriaPorUsuario/exibeParceriaPorUsuario.component';
import { ExibeParceriasPortalComponent } from './portal/exibeParceriasPortal/exibeParceriasPortal.component';
import { DetalheParceriaApi } from './apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from './apis/detalheParceriaPortalApi';
import { DetalheParceriaComponent } from './modulo2/detalheParceria/detalheParceria.component';
import { DetalheParceriaPortalComponent } from './portal/detalheParceriaPortal/detalheParceriaPortal.component';
import { LogonApi } from './apis/logonApi';
import { FormsModule } from '@angular/forms';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../app/@shared/components/dateFormat';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { EmailValidator } from './@shared/components/directive/email.directive';
import { InputZeroValidator } from './@shared/components/directive/validaInputZero.directive';
import { Mascaras } from './@shared/components/Mascaras';
import { FilterPipe } from './portal/exibeParceriasPortal/filter.pipe';
import { InformacaoParceriaM1Component } from './modulo1/informacaoParceria/informacaoParceriaM1.component';
import { DesembolsoComponent } from './modulo2/detalheParceria/demonstrativoDesembolso/desembolso.component';
import { DevolucaoRecursosComponent } from './modulo2/detalheParceria/devolucaoRecursos/devolucaoRecursos.component';
import { ResumoFluxoFinanceiroComponent } from './modulo2/detalheParceria/resumoFluxoFinanceiro/resumoFluxoFinanceiro.component';
import { ValoresPagosComponent } from './modulo1/valoresPagos/valoresPagos.component';
import { EditaisComponent } from './modulo1/editais/editais.component';
import { TermosComponent } from './modulo1/termos/termos.component';
import { ColaboradorComponent } from './modulo2/colaboradores/colaborador.component';
import { PlanoDeTrabalhoComponent } from './modulo1/planoDeTrabalho/planoDeTrabalho.component';
import { DetalheParceriaM1Component } from './modulo1/detalheParceria/detalheParceriaM1.component';
import { DetalheParceriaMTComponent } from './trava/detalheParceria/detalheParceriaMT.component';
import { MonitoramentoComponent } from './modulo3/monitoramentoAvalicao/monitoramento.component';
import { PrestacaoContasParcialComponent } from './modulo2/prestacaoDeContasParcial/prestacaoParcial.component';
import { PrestacaoContasFinalComponent } from './modulo3/prestacaoDeContasFinal/prestacaoFinal.component';
import { DetalheParceriaM3Component } from './modulo3/detalheParceria/detalheParceriaM3.component';
import { ExibeParceriasM3Component } from './modulo3/exibeParcerias/exibeParceriasM3.component';
import { ExibeParceriasM1Component } from './modulo1/exibeParcerias/exibeParceriasM1.component';
import { ExibeParceriasMTComponent } from './trava/exibeParcerias/exibeParceriasMT.component';
import { EmailResponsavelAnaliComponent } from './modulo1/responsavelAnalise/emailResponsavelAnali.component';
import { MonitoraPrestaExcecaoTravaComponent } from './trava/monitoraPrestaExcecaoTrava/monitoraPrestaExcecaoTrava.component';
import { ExcecaoTravaAnaliseComponent } from './modulo3/excecaoTravaAnalise/excecaoTravaAnalise.component';
import { ExcelService } from './@shared/excel-service';
import { KeycloakService, NgxKeycloakModule } from '@procempa/ngx-keycloak';
import { environment } from '../environments/environment';
import { ExibeParceriasM2Component } from './modulo2/exibeParcerias/exibeParceriasM2.component';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';
import { PageNotAuthorizedComponent } from './pageNotAuthorized/pageNotAuthorized.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { RelatorioDesembolsoComponent } from './relatorios/desembolso/relatorioDesembolso.component';
import { RelatoriosApi } from './apis/relatoriosApi';
import { RelatorioDocumentoComponent } from './relatorios/documento/relatorioDocumento.component';
import { RelatorioResumoComponent } from './relatorios/resumo/relatorioResumo.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CadastroTurmaComponent } from './modulo1/turmas/cadastroTurma.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FiltroBuscaComponent } from './@shared/components/filtroBusca/filtroBusca.component';
import { BeneficiarioApi } from './apis/beneficiarioApi';
import { CadastroBeneficarioComponent } from './modulo2/beneficiarios/cadastro/cadastroBeneficiario.component';
import { FrequenciaBeneficarioComponent } from './modulo2/beneficiarios/frequencia/frequenciaBeneficiario.component';
import { VinculoBeneficarioComponent } from './modulo2/beneficiarios/vinculos/vinculosBeneficiario.component';
import { TabsModule } from 'ngx-tabs';
import { ExcecaoTravaFrequenciaComponent } from './modulo2/travaFrequencia/excecaoTravaFrequencia.component';
import { InformacaoParceriaComponent } from './modulo2/informacaoParceria/informacaoParceria.component';
import { RelatorioTurmaComponent } from './relatorios/turma/relatorioTurma.component';
import { ExibeEntidadesComponent } from './modulo2/beneficiarios/entidade/exibeEntidades.component';
import { RelatorioFrequenciaComponent } from './relatorios/frequencia/relatorioFrequencia.component';
import { RelatorioParceriasComponent } from './relatorios/parcerias/relatorioParceria.component';
import { RelatorioTermoComponent } from './relatorios/termoColaboracao/relatorioTermo.component';
import { PublicoPrivadaApi } from './apis/publicoPrivadaApi';
import { RelatorioBeneficiariosComponent } from './relatorios/beneficiarios/relatorioBeneficiarios.component';
import { GestorComponent } from './modulo1/gestor/gestores.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NotificacoesComponent } from './modulo2/detalheParceria/notificacao/notificacoes.component';
import { VerNotificacoesComponent } from './notificacoes/verNotificacoes.component';
import { RelatorioNotificacoesComponent } from './relatorios/notificacoes/relatorioNotificacoes.component';
import { DatepickerPipe } from './@shared/pipes/datepicker';
import { SelectorDateTimeRangeComponent } from './@shared/components/datepicker/selector-datetime-range.component';
import { DateValidatorDirective } from './@shared/components/directive/date-validator.directive';
import { Utils } from './@shared/utils';
import { LogStatusNotificacoesComponent } from './relatorios/notificacoes/logStatusNotificacoes.component';
import { RelatorioStatusTermoComponent } from './relatorios/statusConferenciaTermo/relatorioStatusConfTermo.component';
import { ExibeParceriasLogonComponent } from './liberacaoLogon/parcerias/exibeParcerias/exibeParceriasLogon.component';
import { MaskInputDirective } from './@shared/components/directive/dataMaskInput';
import { RelatorioDocComplemetarArquivoComponent } from './relatorios/docComplementarArquivo/relatorioDocComplementarArquivo.component';
import { RelatorioDevolucaoAplicacaoArquivoComponent } from './relatorios/devolucaoArquivo/relatorioDevolAplicArquivo.component';
import { ParametrosAuditoriaComponent } from './auditoria/parametrizacao/parametrosAuditoria.component';
import { GraficosAuditoriaComponent } from './auditoria/graficos/graficos.component';
import { PriorizacaoCompletaAuditoriaComponent } from './auditoria/priorizacaoCompleta/priorizacaoComplementa.component';
import { VisitacaoAuditoriaComponent } from './auditoria/visitacao/visitacao.component';
import { ChartsModule } from 'ng2-charts';
import { RelatorioValoresPagosComponent } from './relatorios/valoresPagos/relatorioValoresPagos.component';
import { ListaColaboradoresPlanilhaComponent } from './relatorios/listaColaboradoresPlanilha/listaColaboradoresPlanilha.component';
import { AuditorLogonComponent } from './liberacaoLogon/auditor/auditorLogon.component';
import { PrestacaoDeContasComponent } from './modulo2/detalheParceria/prestacaoDeContas/prestacaoDeContas.component';
import { interceptor } from './@core/interceptor';
import { PrestadorServTerComponent } from './cadastroServicoTerceiros/prestadorServTer.component';
import { RelatorioGastoPessoalComponent } from './relatorios/gastoPessoal/relatorioGastoPessoal.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    ExibeParceriaPorUsuarioComponent,
    DetalheParceriaComponent,
    DetalheParceriaMTComponent,
    ExibeParceriasPortalComponent,
    DetalheParceriaPortalComponent,
    InformacaoParceriaComponent,
    InformacaoParceriaM1Component,
    DesembolsoComponent,
    DevolucaoRecursosComponent,
    ResumoFluxoFinanceiroComponent,
    ValoresPagosComponent,
    EditaisComponent,
    TermosComponent,
    PlanoDeTrabalhoComponent,
    DetalheParceriaM1Component,
    MonitoramentoComponent,
    PrestacaoContasParcialComponent,
    PrestacaoContasFinalComponent,
    DetalheParceriaM3Component,
    EmailValidator,
    InputZeroValidator,
    ExibeParceriasM3Component,
    ExibeParceriasM1Component,
    ExibeParceriasM2Component,
    ExibeParceriasMTComponent,
    EmailResponsavelAnaliComponent,
    MonitoraPrestaExcecaoTravaComponent,
    ExcecaoTravaAnaliseComponent,
    ExcecaoTravaFrequenciaComponent,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
    FilterPipe,
    DatepickerPipe,
    SelectorDateTimeRangeComponent,
    RelatorioDesembolsoComponent,
    RelatorioDocumentoComponent,
    RelatorioResumoComponent,
    CadastroTurmaComponent,
    FiltroBuscaComponent,
    CadastroBeneficarioComponent,
    FrequenciaBeneficarioComponent,
    VinculoBeneficarioComponent,
    RelatorioTurmaComponent,
    ExibeEntidadesComponent,
    RelatorioFrequenciaComponent,
    RelatorioTermoComponent,
    RelatorioParceriasComponent,
    RelatorioBeneficiariosComponent,
    GestorComponent,
    ColaboradorComponent,
    NotificacoesComponent,
    VerNotificacoesComponent,
    RelatorioNotificacoesComponent,
    DateValidatorDirective,
    MaskInputDirective,
    LogStatusNotificacoesComponent,
    RelatorioStatusTermoComponent,
    ExibeParceriasLogonComponent,
    RelatorioDocComplemetarArquivoComponent,
    RelatorioDevolucaoAplicacaoArquivoComponent,
    ParametrosAuditoriaComponent,
    GraficosAuditoriaComponent,
    PriorizacaoCompletaAuditoriaComponent,
    VisitacaoAuditoriaComponent,
    RelatorioValoresPagosComponent,
    ListaColaboradoresPlanilhaComponent,
    AuditorLogonComponent,
    PrestacaoDeContasComponent,
    PrestadorServTerComponent,
    RelatorioGastoPessoalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    DataTableModule,
    TextMaskModule,
    AngularFileUploaderModule,
    CurrencyMaskModule,
    NgxExtendedPdfViewerModule,
    NgxPaginationModule,
    NgSelectModule,
    TabsModule,
    NgxCaptchaModule,
    NgxKeycloakModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot(),
    ChartsModule

  ],
  // tslint:disable-next-line:max-line-length

  providers: [DetalheParceriaApi, DetalheParceriaPortalApi, PublicoPrivadaApi, LogonApi, Mascaras, ExcelService, RelatoriosApi, BeneficiarioApi, Utils,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [KeycloakService],
      useFactory: startKeycloak
    },
    { provide: HTTP_INTERCEPTORS, useClass: interceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function startKeycloak(kc: KeycloakService) {
  const url = window.location.origin.split("//");
  //  console.log("route..............", window.location.pathname);


  let env = {
    url: '',
    clientId: '',
    realm: ''
  };


  if (url[1] === 'localhost:4200') {
    env = environment.keycloak;
    //env = environment.keycloak;
  } else {
    const urlIs = url[1].split(".");
    console.log('UrlIs..............', urlIs[0]);
    if (urlIs[0] === 'sgp' || urlIs[0] === 'sgp-hom' || (urlIs[0] === 'sgp-web' && urlIs[1] === 'k8s')) {
      env = environment.acessoPoa;
    }
    if (urlIs[0] === 'sgp-admin' || urlIs[0] === 'sgp-admin-hom' || (urlIs[0] === 'sgp-admin' && urlIs[1] === 'k8s')) {
      // env = environment.keycloak;
      env = environment.keycloak;
    }
    else {
      env = environment.acessoPoa;
    }
  }

  // if (window.location.pathname === '/exibeParceriasPortal') {
  //   env.portal = true;
  //   console.log("é portal");
  // }
  kc.urlsToIgnore = ['/parceriasPortal/*'];
  // return () => kc.init(env, { onLoad: 'login-required' });
  // tslint:disable-next-line:no-console
  return () => kc.init(env);

}

